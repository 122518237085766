import { initializeApp } from "firebase/app";
import { getDatabase, ref, child, get } from "firebase/database";
import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
const dotenv = require("dotenv");
const MerkleTree = require("merkletreejs").MerkleTree;
const keccak256 = require("keccak256");


// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

async function isAllowlistedTier2(address) {
 
  const firebaseConfig = await {
    apiKey: (process.env.REACT_APP_API_KEY).toString(),
    authDomain: (process.env.REACT_APP_AUTH_DOMAIN).toString(),
    databaseURL: (process.env.REACT_APP_DATABASEURL).toString(),
    projectId: (process.env.REACT_APP_PROJECTID).toString(),
    storageBucket: (process.env.REACT_APP_STORAGEBUCKET).toString(),
    messagingSenderId: (process.env.REACT_APP_MESSAGINGSENDERID).toString(),
    appId: (process.env.REACT_APP_APPID).toString()
  };
  let fetchedAddy;
  const app = initializeApp(firebaseConfig);

  console.log("init done")
  const database = getDatabase(app);
  console.log("getting database done")

  const dbRef = ref(getDatabase());
  console.log("Creating database ref done")

  let final = false;
  try{
  await get(child(dbRef, `/`)).then((snapshot) => {
    console.log("trying to get done")
    if (snapshot.exists()) {

      snapshot.forEach((childSnapshot) => {
        if (childSnapshot.val() === address.toString()) {
          console.log(childSnapshot.val());
          final = true
          console.log("final")
          console.log(final)
        }
      });
    } else {
      console.log("No data available");
    }
  }).catch((error) => {
    console.error(error);
  });
}catch(err){
  console.log("Something went wrong")
}
  return final
}

const allowlistAddresses = [
  "0x5257c686F5F98AbCC4Ba001Bf1347662984f72eF",
  "0xfDf3812C4Ab30A5A8f6b24069dc9b8Ab5D7d6686",
  "0x82015A57FCAb84099b668A8BAA7d2Fe453915Ea3"
]

const leafNodes = allowlistAddresses.map(addr => keccak256(addr.toLowerCase().trim()));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
let rootHash = merkleTree.getRoot().toString('hex')

export default isAllowlistedTier2;




















