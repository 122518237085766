const MerkleTree = require("merkletreejs").MerkleTree;
const keccak256 = require("keccak256");

const allowlistAddresses = ["0xfDf3812C4Ab30A5A8f6b24069dc9b8Ab5D7d6686",
  "0x5257c686F5F98AbCC4Ba001Bf1347662984f72eF"
]


const leafNodes = allowlistAddresses.map(addr => keccak256(addr.toLowerCase().trim()));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
let rootHash = merkleTree.getRoot().toString('hex')


function isAllowlisted(address) {
  return allowlistAddresses.includes(address);
}

module.exports = isAllowlisted;


















