import { ethers } from "ethers";
import React, { useState, useEffect } from 'react';
import Reservations from "./Reservations.json";
import {
  Box,
  Flex,
  Text,
  Image,
  Container,
  Heading,
  Button,
} from "@chakra-ui/react";
import ColLogo from "./assets/mainContent/ColLogo.png";
import "./App.css";
import NavBarMint from "./NavBarMint.jsx";
import isAllowlisted from "./Qualified.js";
import getProofTier2 from "./Tier2List.js";
import isAllowlistedTier2 from "./Tier2List.js";
import SwordsIcon from "./lib/swords";
require("dotenv").config();


const ReservationsAddress = "0x262428D0F12366a486dA19A123FAb4528B6a2bf4";

const MintPage = ({ accounts, setAccounts }) => {
  const isConnected = Boolean(accounts[0]);
  const [inputValue, setInputValue] = useState('');
  const [addressResponse, setAddressResponse] = useState("");
  const [addressResponseConnected, setAddressResponseConnected] = useState("");
  const [address, setAddress] = useState("");

  async function connectAccount() {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await provider.getSigner();
      const address = await signer.getAddress();
      setAddress(address)
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await provider.getSigner();
      const address = await signer.getAddress();
      setAddress(address);
    };
    fetchData();
  }, [window.ethereum]);

  const handleSetAddressAndAddressResponse = async () => {
    setAddressResponseConnected("Loading..")
    const provider = new ethers.providers.EtherscanProvider();
    const contract = new ethers.Contract(ReservationsAddress, Reservations.result, provider);
    console.log("Check II")
    console.log("Check I")
    let response = "You are not whitelisted.";


    if (
      (await isAllowlistedTier2(address.toString()) == true) ||
      (await isAllowlistedTier2(await address.toLowerCase().toString()) == true)
    ) {
      response = "You are whitelisted.";
    }

    console.log(response);
    setAddressResponseConnected(response);
  }

  const handleInput = async () => {
    if (ethers.utils.isAddress(inputValue) == false) {
      var notification = document.createElement("div");
      notification.style.position = "fixed";
      notification.style.top = "10px";
      notification.style.right = "55px";
      notification.style.padding = "10px";
      notification.style.backgroundColor = "#c7ab55";
      notification.style.color = "white";
      notification.style.borderRadius = "5px";
      notification.style.fontFamily = "Cinzel Decorative";
      notification.style.marginTop = "5%";
      notification.style.fontWeight = "bold";
      notification.classList.add("notification-animation");
      notification.style.marginLeft = "8%";

      document.body.appendChild(notification);

      // create the close button
      var closeButton = document.createElement("button");
      closeButton.style.float = "right";
      closeButton.innerText = "x";
      notification.appendChild(closeButton);

      // set the notification text
      notification.innerText = "Not a valid ETH Address";

      // add a click event listener to the close button
      closeButton.addEventListener("click", function () {
        notification.style.display = "none";
      });
      setTimeout(function () {
        notification.style.display = "none";
      }, 3000);

      return;
    }
    setAddressResponse("Loading..")
    // Connect to the smart contract
    console.log(inputValue);
    let response = "You are not whitelisted.";

    console.log("isAllowlisted for");
    console.log(inputValue);
    console.log(await isAllowlisted(inputValue));

    if (
      (await isAllowlistedTier2(inputValue) == true) ||
      (await isAllowlistedTier2(await inputValue.toLowerCase()) == true)
    ) {
      response = "You are whitelisted.";
    }
    console.log(response);
    setAddressResponse(response);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <>
      <NavBarMint accounts={accounts} setAccounts={setAccounts} />
      <Container
        as="main"
        maxW={"1700px"}
        centerContent
        mx={"auto"}
        mt={{ base: "1rem", lg: "6rem" }}
      >
        <Image
          src={ColLogo}
          align="center"
          boxSize={{ base: "300px", md: "400px" }}
        />
        {isConnected ? (
          <Box
            color={"#e7e5e4"}
            className="font-cinzel"
            bgColor={"#0b0d12"}
            px={"2.5rem"}
            py={"1rem"}
            rounded={"1rem"}
            my={"1.5rem"}
            border={"1px solid #c7ab55"}
          >
            <Text
              fontWeight={"bold"}
              my={3}
              fontSize={{ base: "1.5rem", md: "2rem" }}
              lineHeight={"2rem"}
            >
              Your ETH Address:
            </Text>
            <Text
              fontWeight={"bold"}
              my={3}
              fontSize={{ base: "1.5rem", md: "2rem", xs: "1.2rem" }}
              lineHeight={"2rem"}
            >
              {address}
            </Text>
            <Button
              mt={1}
              type="button"
              className="font-cinzel"
              style={{ backgroundColor: "#c7ab55", color: "#000" }}
              onClick={handleSetAddressAndAddressResponse}
              fontSize={"1.75rem"}
              fontWeight={"bold"}
              px={"1.5rem"}
              py={"0.25rem"}
              rounded={"1rem"}
              gap={"0.25rem"}
            >
              <SwordsIcon />
              Check
            </Button>
          </Box>
        ) : (
          <Box
            color={"#e7e5e4"}
            className="font-cinzel"
            bgColor={"#0b0d12"}
            px={"2.5rem"}
            py={"1rem"}
            rounded={"1rem"}
            my={"1.5rem"}
            border={"1px solid #c7ab55"}
          >
            <Text
              fontWeight={"bold"}
              my={3}
              fontSize={{ base: "1.5rem", md: "2rem" }}
              lineHeight={"2rem"}
            >
              Enter Your ETH Address
            </Text>
            <input type="text" style={{
              backgroundColor: '#0b0d12',
              color: '#e7e5e4',
              fontFamily: 'font-cinzel',
              rounded: '1rem',
              px: '2.5rem',
              py: '1rem',
              my: '1.5rem',
              border: '1px solid #c7ab55',
              width: '85%'
            }}
              onChange={handleInputChange}
            />
            <Flex justify="center" align="top">
              <Button
                mt={5}
                type="button"
                className="font-cinzel"
                style={{ backgroundColor: "#c7ab55", color: "#000" }}
                onClick={handleInput}
                fontSize={"1.75rem"}
                fontWeight={"bold"}
                px={"1.5rem"}
                py={"0.25rem"}
                rounded={"1rem"}
                gap={"0.25rem"}
              >
                <SwordsIcon />
                Check
              </Button>
            </Flex>
          </Box>
        )}


        <Flex justify="center" align="top">
          {isConnected ? (
            <Text
              align=""
              fontSize="1.75rem"
              className="ConnectToAdjust"
              fontFamily="Cinzel Decorative"
              color="white"
            >
              {addressResponseConnected}
            </Text>
          ) : (
            <Text
              align=""
              fontSize="1.75rem"
              className="ConnectToAdjust"
              fontFamily="Cinzel Decorative"
              color="white"
            >
              {addressResponse}
            </Text>
          )}
        </Flex>
      </Container>
    </>
  );
};
export default MintPage;

async function HandleNetworkSwitch() {

  try {
    if (!window.ethereum) throw new Error("No crypto wallet found");

    if (window.ethereum.networkVersion !== 1) {

      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: "0x1" }]
        });

      } catch (err) {
        // This error code indicates that the chain has not been added to MetaMask
        if (err.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                ...networks['homestead']
              }]
          });
        }
      }
    }
  } catch (err) {
    console.log(err.message);
  }
}

const networks = {
  homestead: {
    chainId: `0x${Number(1).toString(16)}`,
    chainName: "Ethereum Mainnet",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18
    },
    rpcUrls: ["https://api.mycryptoapi.com/eth/"],
    blockExplorerUrls: ["https://etherscan.io/"]
  }
};

/**
 * 
 * 
 *        <Image 
        src="path/to/allowlisted-image.jpg"
        isHidden={!isAllowlisted(accounts[0])}
      />
MAINNET: 
async function HandleNetworkSwitch() {

    try {
        if (!window.ethereum) throw new Error("No crypto wallet found");

        if (window.ethereum.networkVersion !== 1) {

            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: "0x1" }]
                });

            } catch (err) {
                // This error code indicates that the chain has not been added to MetaMask
                if (err.code === 4902) {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                ...networks['homestead']
                            }]
                    });
                }
            }
        }
    } catch (err) {
        console.log(err.message);
    }
}

const networks = {
    homestead: {
        chainId: `0x${Number(1).toString(16)}`,
        chainName: "Ethereum Mainnet",
        nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: ["https://api.mycryptoapi.com/eth/"],
        blockExplorerUrls: ["https://etherscan.io/"]
    }
};
GÖRLI:
async function HandleNetworkSwitch() {
    try {
        if (!window.ethereum) throw new Error("No crypto wallet found");

        if (window.ethereum.networkVersion !== 5) {

            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: "0x5" }]
                });
            } catch (err) {
                // This error code indicates that the chain has not been added to MetaMask
                if (err.code === 4902) {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                ...networks['goerli']
                            }]
                    });
                }
            }
        }
    } catch (err) {
        console.log(err.message);
    }
}

const networks = {
    goerli: {
        chainId: `0x${Number(5).toString(16)}`,
        chainName: "Goerli",
        nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: ["https://goerli.infura.io/v3/YOUR-PROJECT-ID"],
        blockExplorerUrls: ["https://goerli.etherscan.io/"]
    }
};

 */
